import React from 'react'
import { Link } from 'gatsby'

import Layout from '../components/Layout'
import '../styles/styles.scss'

const Error404Page = () => {
  const title = 'Bergamot - 404'
  const description = 'Nothing to see here!'
  return (
    <Layout title={title} description={description}>
      <main className="page404">
        <section className="section section--404">
          <div className="container intro content">
            <h1 className="page404__title">404</h1>
            <p className="page404__text">
              The page you are looking for does not exist. How you got here is a mystery. But you
              can click the button below to go back to the homepage.
            </p>
            <Link to="/" className="button is-outlined is-rounded is-primary is-medium">
              HOME
            </Link>
          </div>
        </section>
      </main>
    </Layout>
  )
}
export default Error404Page
